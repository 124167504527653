import '../styles/App.css'
import { RefObject, useEffect, useRef, useState } from 'react'
import { FastAverageColor } from 'fast-average-color';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import options from '../components/mainpage/ParticleConfig'
import { loadFull } from 'tsparticles'

function App() {

  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  });

  const [fursona, setFursona] = useState('/media/yeen-min.webp');
  const [audioObject, setAudioObject] = useState<HTMLAudioElement | undefined>(undefined);
  const [trans, setTrans] = useState(false);
  const imgContainerRef = useRef<HTMLLinkElement>();

  const getColor = () => {
    if (imgContainerRef) {
      const fac = new FastAverageColor();

      const img = imgContainerRef.current?.querySelector('img');

      if (img) {
        fac.getColorAsync(img)
          .then(color => {
            img.style.filter = `drop-shadow(0px 0px 64px ${color.hex})`;
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  };

  useEffect(() => {
    getColor();
  });

  const changeFursona = (imagePath: string) => {
    setFursona(imagePath);
    getColor();
  } 

  const startBossaNova = () => {
    if (!audioObject) {
      const audio = new Audio('/media/bossa_nova.mp3');
      setAudioObject(audio);

      audio.loop = true;
      audio.play();

      setTrans(true);

    } else {
      audioObject.pause();
      setAudioObject(undefined);
    }
  };

  return (
    <>
      
      { (init && audioObject != undefined) ? <Particles options={options}  /> : '' }

      <div className='flex text-xl flex-grow justify-center items-center mt-4'>
        <h1 onClick={(_) => setTrans(!trans)} className={`${trans ? 'trans-color' : 'bg-teal-500'} rounded-md text-black font-bold px-4 py-2 tracking-wide`}>DEVON</h1>
        <h1 className='pl-4 tracking-wider'>DOG</h1>
      </div>
      <p className='pt-4'>~ 23 y.o. × quirky trans girl × she/her ~</p>
      <p>software dev × graphics designer</p>
      <div className='mt-8'>
        <p>VIEW MY</p>
        <p>[ <span style={{ cursor: 'pointer', textDecoration: fursona == '/media/yeen-min.webp' ? 'underline' : 'none' }} onClick={(_) => changeFursona('/media/yeen-min.webp')}>HYENA</span> |&nbsp;
            <span style={{ cursor: 'pointer', textDecoration: fursona == '/media/18-min.webp' ? 'underline' : 'none' }} onClick={(_) => changeFursona('/media/18-min.webp')}>DEER</span> ]</p>
      </div>
      <div className='mt-12 flex justify-center items-center'>
        <a ref={imgContainerRef as RefObject<any>}>
          <img className={audioObject != undefined ? 'spin' : ''} rel='preload' src={fursona} style={{ height: '45vh', userSelect: 'none', transition: "filter 0.5s ease-in-out", filter: `drop-shadow(0px 0px 0px #181818)` }} alt="Vite logo" />
        </a>
      </div>
      <div className='mt-12'>
        <span>So, what shall we do? <span className='blinking-cursor'>_</span></span> 
        <div className='mt-4 flex flex-wrap flex-row gap-8 justify-center items-center'>
          {/* <button className='main-button'>LEARN MORE ABOUT DEVON</button> */}
          <button onClick={() => window.location.href = "/gallery"} className='main-button'>VIEW ART GALLERY</button>
          <button onClick={() => window.location.href = "/socials"} className='main-button'>WHERE TO FIND ME!</button>
          <button onClick={startBossaNova} className='main-button'>
            { audioObject != undefined ? 'lol you fool' : 'COPY MY STEUER ID' }
          </button>
        </div>
      </div>
      <div className='mt-4 flex flex-row justify-center'>
        <p>&copy; { new Date().getFullYear() }&nbsp;-&nbsp;</p>
        <a style={{ textDecoration: 'underline', color: 'currentcolor' }} href="https://kkmji.dev">KKMJI.DEV</a>
      </div>
    </>
  )
}

export default App
