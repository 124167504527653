import "../styles/Socials.css"

export default function SocialPage() {

    const socials = [
        {
            icon: "github",
            text: "GitHub - @kokumaji",
            sub: "This is where all my code is stored. Not sure what else to say.",
            url: "https://github.com/kokumaji"
        },
        {
            icon: "twitch",
            text: "Twitch - @devonyeen",
            sub: "Occasionally streaming (I am still new to this)",
            url: "https://www.twitch.tv/devonyeen"
        },
        {
            icon: "tiktok",
            text: "TikTok - @devonhyena",
            sub: "Funny clips or whatever comes into my mind for funny content.",
            url: "https://www.tiktok.com/@devonhyena"
        },
        {
            icon: "kofi",
            text: "Ko-Fi - @devondog",
            sub: "Feel free to donate!",
            url: "https://ko-fi.com/devondog"
        },
        {
            icon: "discord",
            text: "Join My Discord!",
            sub: "A place to hang out with the community!",
            url: "https://discord.gg/tz6yh52eWF"
        },
        {
            icon: "telegram",
            text: "Telegram - @kokumaji",
            sub: "Feel free to shoot me a DM!",
            url: "https://t.me/kokumaji"
        },
        {
            icon: "x",
            text: "Twitter/X - @devonhyena",
            sub: "(Life) updates and general chatter.",
            url: "https://x.com/devonhyena"
        },
        {
            icon: "bluesky",
            text: "BlueSky - @devon.dog",
            sub: "Barely used, but it exists :)",
            url: "https://bsky.app/profile/devon.dog"
        },
    ];

    const buildIconUrl = (icon: string) => {
        return `https://raw.githubusercontent.com/simple-icons/simple-icons/develop/icons/${icon}.svg`;
    }

    return (
        <>
            <div className="mt-12">
                <a style={{ color: 'currentcolor', textDecoration: 'underline', fontSize: '1.25rem' }} href="/">⬅️ Go Back to Home</a>

                <div className="mt-12">
                    {
                        socials.map((social) => (
                            <span style={{ cursor: 'pointer' }} onMouseUp={() => social.url != "#" ? window.open(social.url, '_blank') : ""} className="flex flex-row gap-8 items-center px-8 py-4 mt-4 rounded-lg border-2" id={ 'social-' + social.icon }>
                                <img className="social-button-icon" src={buildIconUrl(social.icon)} alt={social.text} />
                                <div className="flex flex-col text-left">
                                    <label htmlFor={ 'social-' + social.icon }>{ social.text }</label>
                                    <label className="text-gray-500" htmlFor={ 'social-' + social.icon }>{ social.sub }</label>
                                </div>
                            </span>
                        ))
                    }
                    <img src="" alt="" />
                </div>

            </div>
        </>
    );   
}