const ImageGallery: React.FC = () => {
    const imagePaths = [
        "/media/gallery/01 - Paw Icon.png",
        "/media/gallery/01 - Pixel Icon.png",
        "/media/gallery/02 - Regular Icon.png",
        "/media/gallery/03 - Agender Pride.jpg",
        "/media/gallery/03 - HackerYeen.jpg",
        "/media/gallery/05 - Long Yeen.png",
        "/media/gallery/06 - Couples YCH.png",
        "/media/gallery/06 - Butter Yeen.png",
        "/media/gallery/07 - Christmas YCH.GIF",
        "/media/gallery/08 - Multi Character.jpg",
        "/media/gallery/08 - Koda Pat.png",
        "/media/gallery/09 - Clumsy YCH.png",
        "/media/gallery/10 - Femboy YCH.png",
        "/media/gallery/10 - Koda x Lyoka.jpg",
        "/media/gallery/11 - Tail Wag.GIF",
        "/media/gallery/13 - Christmas Stocking.png",
        "/media/gallery/14 - Paw YCH.png",
        "/media/gallery/15 - Koda Telegram Stickers.png",
        "/media/gallery/16 - Wiggle Icon.gif",
        "/media/gallery/17 - Paw Icon.png",
        "/media/gallery/20 - Koda (old) - Stretching.jpg",
        "/media/gallery/22 - Koda (old) Stocking YCH.png",
        "/media/gallery/23 - Aiden Headshot.jpg",
        "/media/gallery/24 - Spooky Ghosts YCH.png",
        "/media/gallery/25 - Aussie Dog Pfp.jpg",
        "/media/gallery/26 - Devon Headshot.JPG",
        "/media/gallery/XX - Sketch Icon (old).jpg"
    ];

    return (
        <>
            <div>
                <div className="mt-12">
                    <a style={{ color: 'currentcolor', textDecoration: 'underline', fontSize: '1.25rem' }} href="/">⬅️ Go Back to Home</a>
                </div>
                <div className="grid  sm:grid-cols-1 md:grid-cols-3 gap-4 mt-12">
                    {imagePaths.map((imagePath, index) => (
                        <div key={index} className="relative">
                            <img
                                src={imagePath}
                                alt={`Image ${index}`}
                                style={{ width: '300px' }}
                            />
                            <div style={{ width: '300px' }} className="absolute top-0 left-0 h-full bg-black opacity-5"></div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ImageGallery;